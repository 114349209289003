import navUtil from "@/components/global/CustomizeNav/util";
import {
  getProductList,
  inStock,
  findDetail,
  inStockSignle,
  exportOrder,
  getImg,
  salesOrderPrint,findSalesOrderPrint, sumbitAttorney, downAttorney, sendLadingContract, backCheckproxysign, downContractById,
  exportSalesOrder
} from "./api";
import Util from "@/utils/utils";
import { publicPath } from "@/common/constant";
const BASE_API = publicPath; // 地址
export default {
  data() {
    return {
      // 主账户：isMainAccount==false 主账户显示入库按钮
      isMain:this.$store.state.user.userInfo.customer.isMainAccount==false?true:false,
      dataList: [],
      navList: [],
      isChange: false,
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },

      {
        path: "/dmspurchaseProgress",
        name: "detail",
        title: "海信订单汇总（入库）"
      }
      ],
      total: 0,
      modelShopShow: false,
      showTotalColumns: ['出库数量', '签收数量', '下单数量', '金额', '审核通过数量', '发货单数量', '发货单金额', '甩货数量', '入库数量', '发货单体积合计(m³)', '发货单金额(元)',],
      tcolumns: [
        {
        label: "采购订单编码",
        prop: "orderCode",
        sortable: 'custom',
        sortName: "salesOrder.orderCode",
        slot: "orderCode",
        width: 400,
      },
      {
        label: "订单创建日期",
        prop: "orderDate",
        sortable: 'custom',
        sortName: "salesOrder.createdDate",
        width: 200,
      },
      {
        label: "截止订单失效天数",
        prop: "effectiveDays",
        // sortable: 'custom',
        // sortName: "salesOrder.effectiveDays",
        width: 200,
      },
      {
        label: "下单商家编码",
        prop: "mdmCode",

        ellipsis: true,
        width: 200,
      },
      {
        label: "下单商家名称",
        prop: "fullName",
        ellipsis: true,
        width: 200,
      },
      {
        label: "订单类型",
        prop: "orderTypeName",

        ellipsis: true,
        width: 200,
      },
      {
        label: "订单状态",
        prop: "orderStatusName",
        key: '23',
        width: 200,
      },
      {
        label: "供应商",
        prop: "fwOrgName",

        ellipsis: true,
        width: 200,
      },
      {
        label: "物料组",
        prop: "matklName",

        ellipsis: true,
        width: 200,
      },
      {
        label: "型号",
        prop: "zzprdmodel",

        ellipsis: true,
        width: 160,
      },
      {
        label: "批次",
        prop: "batch",

        ellipsis: true,
        width: 160,
      },
      {
        label: "定价组",
        prop: "priceGroupName",

        ellipsis: true,
        width: 160,
      },
      {
        label: "补差类型",
        prop: "makeUpType",

        ellipsis: true,
        width: 160,
      },
      {
        label: "返利比例(%)",
        prop: "rebates",
        align: "right",
        ellipsis: true,
        width: 200,
      },
      {
        label: "提货价",
        prop: "basePrice",
        align: "right",
        ellipsis: true,
        width: 160,
      },
      {
        label: "政策直扣",
        prop: "policyDeduct",

        ellipsis: true,
        width: 160,
      },
      {
        label: "政策折扣",
        prop: "policyDiscount",
        align: 'right',
        ellipsis: true,
        width: 160,
      },
      {
        label: "固定折扣",
        prop: "fixedDiscount",
        align: 'right',
        ellipsis: true,
        width: 160,
      },
      {
        label: "开票价",
        prop: "billPrice",
        align: 'right',
        ellipsis: true,
        width: 160,
      },
      {
        label: "下单数量",
        prop: "qty",
        key: '32424',
        align: 'right',
        ellipsis: true,
        width: 160,
      },
      {
        label: "金额",
        prop: "atmPrice",
        align: 'right',
        ellipsis: true,
        width: 200,
      },
      {
        label: "政策编码",
        prop: "policyCode",

        ellipsis: true,
        width: 200,
      },
      {
        label: "要求到货周",
        prop: "weekName",

        ellipsis: true,
        width: 200,
      },
      {
        label: "订单评审日期",
        prop: "checkDate",

        ellipsis: true,
        width: 200,
      },
      {
        label: "扣款日期",
        prop: "payDate",
        ellipsis: true,
        width: 200,
      },
      {
        label: "发货单号",
        prop: "sapOrderCode",
        ellipsis: true,
        slot: "sapOrderCode",
        width: 200,
      },
      {
        label: "发货单状态",
        prop: "statusName",
        ellipsis: true,
        width: 200,
      },
      {
        label: "发货单创建时间",
        prop: "sapOrderDate",

        ellipsis: true,
        width: 200,
      },
      {
        label: "签收时间",
        prop: "signDate",
        ellipsis: true,
        width: 200,
      },
      {
        label: "发货单体积合计(m³)",
        prop: "allLoadVolume",
        align: 'right',
        ellipsis: true,
        width: 200,
      },
      {
        label: "发货单金额(元)",
        prop: "sendAtmPrice",
        align: 'right',
        ellipsis: true,
        width: 200,
      },
      {
        label: "发货单数量",
        prop: "erpQty",
        align: 'right',
        ellipsis: true,
        width: 200,
      },
      {
        label: "出库数量",
        prop: "outboundQty",
        align: 'right',
        // ellipsis: true,
        width: 200,
      },
      {
        label: "签收数量",
        prop: "signQty",
        align: 'right',
        ellipsis: true,
        width: 200,
      },
      {
        label: "甩货数量",
        prop: "shQty",
        align: 'right',
        ellipsis: true,
        width: 200,
      },
      {
        label: "入库数量",
        prop: "actualQty",
        ellipsis: true,
        width: 200,
        align: 'right',
      },
      {
        label: "允许提前交货",
        prop: "isAllowAdvancDeliver",
        ellipsis: true,
        width: 200,
      },
      {
        label: "晚到标识",
        prop: "appointmentLate",

        ellipsis: true,
        width: 200,
      },
      {
        label: "发货日期",
        prop: "outboundDate",

        ellipsis: true,
        width: 200,
      },
      {
        label: "预计送达日期",
        prop: "expectedDeliveryDate",

        ellipsis: true,
        width: 200,
      },
      {
        label: "实际签收时间",
        prop: "signedDate",

        ellipsis: true,
        width: 200,
      },
      {
        label: "配送方式",
        prop: "deliveryTypeName",

        ellipsis: true,
        width: 200,
      },
      {
          label: "自提委托书",
          prop: "attorneyLetter",
          ellipsis: true,
          slot: "attorneyLetter",
          width: 500,
          value: true
        },
      {
        label: "收货地址",
        prop: "address",

        ellipsis: true,
        width: 200,
      },
      {
        label: "联系人-联系电话",
        prop: "contactAndMoblie",
        ellipsis: true,
        width: 200,
      },
      {
        label: "统仓收货标识",
        prop: "shareFlagName",

        ellipsis: true,
        width: 200,
      },
      {
        label: "收货统仓",
        prop: "shareWarehouseName",

        ellipsis: true,
        width: 200,
      },
      {
        label: "收货分销编码",
        prop: "fenXiaoCode",

        ellipsis: true,
        width: 200,
      },
      {
        label: "收货分销商名称",
        prop: "fenXiaoName",

        ellipsis: true,
        width: 200,
      },
      {
        label: "采购下单平台",
        prop: "orderSourceName",

        ellipsis: true,
        width: 200,
      },
      {
        label: "下单用户",
        prop: "customizedCreator",
        ellipsis: true,
        width: 200,
      },
      {
        label: "操作",
        prop: "",
        key: "btnGroup",
        slot: "btnGroup",
        fixed: 'right',
        width: 200
      }
      ],
      tableData: [],
      tabelIsLoading: false,
      modelShow: false,
      //x 最大宽度 y 最大高度
      scroll: {
        x: 2100
      },
      tableWidth: 1188,
      modelDataConfirm: [], // 查询结果
      pagination: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
        sortName: "",
        sortOrder: ""
      },
      loading: false,
      terms: {},
      modelShowSign: false,
      infoinStock: {},
      pager: {
        count: '',
        pageNo: 1,
        pageSize: 10
      },
      stock: [],
      stockData: [],
      tablecolumns: [{
        title: '仓库',
        dataIndex: 'name',
        key: 'name',
      },

      ],
      selKeys: null,
      selKeys2: null,
      shopKey: '',
      selRows: [],
      filterForm: {
        queryChild: '2',
        queryStore: 0
      },
      onlyChid: false, //只展示分销商
      onlyZhu: true, //只展示我的
      orderLoading: false,
      salesOrderLoading: false,
      imgURL: [],
      onluyOneMessage: false,
      printData:{
        Master:{},
        Details:[],
        countTotal:'',
        amount:'',
      },//打印接口数据
	    isDataOk: true,//打印接口数据是否返回
      ztVisible: false,
      sqVisible: false, // 授权弹窗
      scanVisible: false, // 弹出二维码弹窗
      InfoTips: false,
      mesageInfoTips: '',
      contractId:'', // 授权书id
      pageLoadFlag:false,
      isLoading:false,
      columnsAll:[],// 记录所有列数据 调整列入参用
      key1:'',
      isExpand: false // 操作按钮是否展开
    }
  },
  created() {
    this.columnsAll = this.tcolumns;
  },
  mounted() {
    if(this.$route.query.sorce == 'index'){
      this.filterForm.queryStore = 1
    }
    // 如果是子账户不显示操作栏
    if (this.$store.state.user.userInfo.customer.isMainAccount == true) {
      this.tcolumns.pop()
    }
    //导航数据
    this.navList = navUtil.orderList;
  },
  methods: {
    // 点击自提委托书打印
    linkClickZT(obj) {
      if(obj.link) {
        window.open(BASE_API+`/orderLadingContract/downContractById.api?contractId=`+ obj.link,'_blank')
      }
    },
    // 打开自提委托书的弹窗
    openZT(){
      // 如果没勾选 弹出当前提示
      if(this.selRows.length <= 0 ) {
        this.$message.warning('请先选择订单')
        return
      }
      // 如果勾选数据 不满足后端返回的ladingOperateFlag == Y 就给提示 ladingOperateFlag:是否可以操作签署委托书
      // const OperateFlagRows =  this.selRows.filter(it => it.ladingOperateFlag !== 'Y')
      // const OperateFlagRowsNum =  this.selRows.filter(it => it.ladingOperateFlag !== 'Y').map(it=>it.orderCode).join(',')
      // if(OperateFlagRows.length > 0) {
      //   this.mesageInfoTips = (`${OperateFlagRowsNum}不能进行签署自提委托书的操作，请重新选择`)
      //   this.InfoTips = true
      //   return
      // }
      // 如果勾选的数据不是自提 提示 BHO202212310131，BHO202212310312的配送方式不是自提，无需签署自提委托书。 it.shareFlagName是否是统仓
      const inconformitySelRows =  this.selRows.filter(it => it.deliveryTypeCode !== '01')
      const inconformitySelRowsNum =  this.selRows.filter(it => it.deliveryTypeCode !== '01').map(it=>it.orderCode).join(',')
      if(inconformitySelRows.length > 0) {
        this.mesageInfoTips = (`${inconformitySelRowsNum}的配送方式不是自提，无需签署自提委托书`)
        this.InfoTips = true
        return
      }
      // // ）如果勾选的多个订单中有没完成分拣的，没有提交SAP的，此类订单没有提货仓库，
      // // ①对于海信订单：弹窗提示：“BHO202212310131，BHO202212310312订单流程未到SAP提货仓库确认，暂不可签署自提委托书
      const notsapSelRows =  this.selRows.filter(it => it.sapFlag =='N' || it.packFlag == '未分拣' )
      const notsapSelRowsNum =  this.selRows.filter(it => it.sapFlag =='N' || it.packFlag == '未分拣').map(it=>it.orderCode).join(',')
      if(notsapSelRows.length > 0) {
        this.mesageInfoTips = (`${notsapSelRowsNum}订单流程未到SAP提货仓库确认，暂不可签署自提委托书`)
        this.InfoTips = true
        return
      }
      // dms判断 对于销售订单：弹窗提示：“S202212310131，P202212310312订单为完成提货仓库审核确认，暂不可签署自提委托书。”； 根据物流状态  主要不是创建成功 就提示
      // （4）如果勾选的订单已经签了委托书，弹窗示：“BHO202212310131，BHO202212310312已签署自提委托书，请勿重复签署。
      const signSelRows =  this.selRows.filter(it => (it.ladingStatus == 1 || it.ladingStatus == 2 ))
      const signSelRowsNum =  this.selRows.filter(it => (it.ladingStatus == 1 || it.ladingStatus == 2) ).map(it=>it.orderCode).join(',')
      if(signSelRows.length > 0) {
        this.mesageInfoTips = (`${signSelRowsNum}已签署自提委托书，请勿重复签署`)
        this.InfoTips = true
        return
      }
      // 自提委托书
      this.ztVisible = true
    },
    // 获取填写的自提委托书的信息
    getztInfo(Info){
      this.pageLoadFlag = true
      let proArr = this.selRows.map(item => {
        return {
          orderCode: item.sapOrderCode,
          spartName: item.matklName ? item.matklName:'',
          productName: item.zzprdmodel ? item.zzprdmodel:'',
          number: item.qty,
          warehouse: item.shareWarehouseName ? item.shareWarehouseName : '',
         };
      })
      let data = {
        // serviceCode: "sendLadingContract",
        type: '1',
        bossPhone: this.$store.state.user.userInfo.account.mobile,
        fullName: this.$store.state.user.userInfo.customer.customerName,
        order: proArr,
        carName: Info.name,
        carPhone: Info.phone,
        carNumber: Info.carNum,
        carId: Info.idCard
      }
      // 提交以后接口返回是否授权 返回是的话不展示去授权弹窗  返回否的话就展示授权弹窗 进行下面的操作
      sendLadingContract( data ).then( res => {
        if( res.data.code == '1' ){
          this.pageLoadFlag = false
          this.$message.warning( res.data.msg )
          // this.ztVisible = false
        } else {
          if(res.data.type == 0) {
            this.$message.warning(res.data.msg)
            this.contractId = res.data.contractId
            //   授权弹窗
            this.sqVisible = true
            this.ztVisible = false
            this.pageLoadFlag = false
            this.getData()
          } else {
            this.$message.warning(res.data.msg)
            this.contractId =''
            this.ztVisible = false
            this.pageLoadFlag = false
            this.getData()
          }
        }
      })
    },
    // 获取是否签署数据
   async getsqInfo(data){
     //  data 上个页面传过来的数据 1 去签署  0 不签署
     // 如果是点击去签署 就关闭当前页面 打开二维码页面
      if(data == 1){
        this.scanVisible = true
        this.getData()
      }
      this.isLoading = true
      let dataA = {
        pickType: data,
        contractId:this.contractId,
      }
      backCheckproxysign(dataA).then(res=>{
        if(res.code == 1) {
          this.$message.warning(res.data.msg)
          this.getData()
          this.contractId = ''
          this.isLoading = false
        } else {
          this.contractId = ''
          this.isLoading = false
          this.getData()
        }
      })
    },
    // 二维码页面关闭页面
    getscanInfo(){
      this.getData()
    },

    // 回单影像
    requestImg(documentNum) {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        documentNum
      }
      getImg(data).then(res => {
        var realData = JSON.parse(res.data);
        if (realData.data) {
          this.imgURL = realData.data;
          this.onluyOneMessage = true;
        } else {
          this.$message.warning('暂无回单影像');
        }

      })
    },
    exportOrder() {
      this.orderLoading = true;
      let exportObj = {
      ...this.terms,
      ...this.filterForm
      };
      delete exportObj.orderBy;
      exportOrder(exportObj).then(res => {
        console.log('导出返回结果', res)
        if(res.status == 200){
          this.$message.success('下载成功，请到下载中心查看!')
        }
        Util.blobToJson(res.data).then(content => {
          console.log('blobress',content)
          if (content && content.code == 'success') {
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err => {
          // Util.downloadFile(res.data, '海信进度汇总列表.xls')
        }).finally(() => {
          this.orderLoading = false;
        })
      }).catch(err => {
        this.orderLoading = false;
      });
    },
   async exportSalesOrder(){
      this.salesOrderLoading = true;
      let exportObj = {
        ...this.terms,
        ...this.filterForm
      };
      console.log('导出参数',exportObj)
      if(!exportObj.outDate){
        this.$message.warning('请选择发货日期')
        this.salesOrderLoading = false;
        return
      }
      try {
        const res = await exportSalesOrder(exportObj);
        console.log('导出返回结果 详情', res)
        if(res.data && res.data.data.code == 'success'){
          this.$message.success('下载成功，请到下载中心查看!')
        }
      } catch (error) {
        this.$message.error(`导出错误！`);
      } finally {
        this.salesOrderLoading = false;
      }
    },
    downloadFile(blob, name) {
      var reader = new FileReader();
      reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
      reader.onload = function (e) {
        // 转换完成，创建一个a标签用于下载
        var a = document.createElement("a");
        a.download = name;
        a.href = e.target.result;
        $("body").append(a); // 修复firefox中无法触发click
        a.click();
        $(a).remove();
      }
    },
    // 单笔入库
    getReason(value,) {
      this.selKeys2 = value
      this.inSignle()
    },
    // 批量入库--不选择门店
    getChooseId(value) {
      this.selKeys = value
      this.allIn()
    },
    getChooseShop(value) {
      this.selKeys = value.ck
      this.shopKey = value.shop
      this.allIn2()

    },
    async openStock2(data) {
      this.modelShowSign = true
      this.infoinStock = data
      // 查询当前选择的列表详情接口
      let adata = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        loginType: "CS",
        purchaseOrderId: data.purchaseOrderId
      }
      let res = await findDetail(adata)
      let request = JSON.parse(res.data)
      this.cherkInfo = request.data
    },
    // 单个入库修改仓库选项
    onChangeSign(selKeys, selRows) {
      this.selKeys2 = selRows[0].id
    },
    // 单个入库
    inSignle() {
      this.cherkInfo.purchaseOrderItem.forEach(item => {
        // 入库数量
        item.shippedBqty = item.waitStockBQty
        item.warehouseId = this.selKeys2;
      });
      let Finished = null
      if (this.cherkInfo.purchaseOrderItem[0].shippedBqty == this.cherkInfo.purchaseOrderItem[0].waitStockBQty) {
        Finished = true
      }
      if (this.cherkInfo.purchaseOrderItem[0].shippedBqty < this.cherkInfo.purchaseOrderItem[0].waitStockBQty) {
        Finished = false
      }

      // 单笔入库
      inStockSignle({
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        data: {
          isFinished: Finished,
          purchaseOrderId: this.cherkInfo.id,
          purchaseOrderItem: this.cherkInfo.purchaseOrderItem
        }
      }).then(res => {
        let request = JSON.parse(res.data)
        if (request.code == 0) {
          this.$message.success('入库成功')
          this.modelShowSign = false
          this.getData()
        } else {
          this.$message.warning(request.msg)
          this.modelShowSign = false
        }
      })
    },
    // 批量打开
    openStock() {
      // 如果勾选数据 waitStorage == Y && operateFlag == Y  就给提示 ladingOperateFlag:是否可以操作批量入库
      const OperateFlagRows =  this.selRows.filter(it => (it.waitStorage == 'N' || it.operateFlag == 'N') )
      const OperateFlagRowsNum =  this.selRows.filter(it => (it.waitStorage == 'N' || it.operateFlag == 'N')).map(it=>it.orderCode).join(',')
      if(OperateFlagRows.length > 0) {
        this.mesageInfoTips = (`${OperateFlagRowsNum}不能进行批量入库的操作，请重新选择`)
        this.InfoTips = true
        return
      }
      if (this.selRows.length > 0) {
        let arrFour = []
        let elseArr = []
        for (let i = 0; i < this.selRows.length; i++) {
          // deliveryTypeName
          if (this.selRows[i].deliveryTypeCode == "04") {
            arrFour.push(this.selRows[i])
          }
          if (this.selRows[i].deliveryTypeCode !== "04") {
            elseArr.push(this.selRows[i])
          }
        }
        if (arrFour.length == this.selRows.length) {
          this.modelShopShow = true;
        }
        if (elseArr.length == this.selRows.length) {
          this.modelShow = true;
        }
        if (elseArr.length !== this.selRows.length && arrFour.length !== this.selRows.length) {
          this.$message.warning('直配到用户订单不能和其他订单一起入库,请重新选择')
        }
      } else {
        this.$message.warning('请选择数据')
      }
    },

    // 批量入库
    allIn() {
      let wId = []
      this.selRows.forEach(item => {
        wId.push(String(item.purchaseOrderId))
      })
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        warehouseId: this.selKeys,
        directOrderIds: [],
        storeCode: "",
        purchaseOrderIds: wId
      }
      inStock(data).then(res => {
        let request = JSON.parse(res.data)
        if (request.code == 0) {
          this.$message.success('入库成功')
          this.modelShow = false
          this.getData()
        } else {
          this.$message.warning(request.msg)
          this.modelShow = false
        }
      })
    },
    // 批量入库 -- 添加门店参数
    allIn2() {
      let wId = [];
      this.selRows.forEach(item => {
        wId.push(String(item.purchaseOrderId))
      });
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        warehouseId: this.selKeys,
        directOrderIds: [],
        storeCode: this.shopKey,
        purchaseOrderIds: wId
      };
      inStock(data).then(res => {
        let request = JSON.parse(res.data);
        if (request.code == 0) {
          this.$message.success("入库成功");
          this.modelShopShow = false;
          this.getData();
        } else {
          this.$message.warning(request.msg);
          this.modelShopShow = false;
        }
      });
    },
    // 查看待入库数据
    inStock(e) {
      if (e.target.checked == true) {
        this.filterForm.queryStore = 1,
          this.tabelIsLoading = true;
        const data = {
          ...this.filterForm,
          pageNo: 1,
          pageSize: 10,
          ...this.terms,
        };
        getProductList(data).then(res => {
          let data;
          if (typeof res.data == "string") {
            data = JSON.parse(res.data);
          } else {
            data = res.data;
          }
          this.tableData = data.list
          this.pagination.pageNo = data.currentPage
          this.pagination.pageSize = 10
          this.pagination.count = data.totalCount
          this.total = data.totalCount
          let tableData;
          tableData = data.list
          if (tableData.length > 0) {
            let result = [];
            let obj = {};
            for (let i = 0; i < tableData.length; i++) {
              tableData[i].attorneyLetter = ''
              if (!obj[tableData[i].orderCode]) {
                result.push(tableData[i]);
                obj[tableData[i].orderCode] = true;
              }

              if( tableData[i].ladingStatus == '2') {
                tableData[i].attorneyLetter =  tableData[i].link
              }
              if(  tableData[i].ladingStatus == 1 ) {
                tableData[i].attorneyLetter = '签署中'
              }
            }
          }
          this.pagination.pageNo = res.data.currentPage
          // this.pagination.pageSize = 10
          this.pagination.count = res.data.totalCount
          this.total = res.data.totalCount
          this.tabelIsLoading = false;
          this.tableData = tableData
          // this.tcolumns = JSON.parse(JSON.stringify(this.$refs.setTableRow.showList));
          // this.listChange(this.tcolumns);
        });
      } else {
        this.filterForm.queryStore = 0,
          this.pagination.pageNo = 1
        this.pagination.pageSize = 10
        this.getData()
      }
    },
    // 合计
    getSummariesFun(param) {
      const {
        columns,
        data
      } = param;
      let result = [];
      let obj = {};
      for (let i = 0; i < data.length; i++) {

        if (!obj[data[i].orderCode]) {
          result.push(data[i]);
          obj[data[i].orderCode] = true;
        }
      }

      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (!this.showTotalColumns.includes(column.label)) {
          return;
        }
        if (column.label == '下单数量' || column.label == '金额') {
          const values = result.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = sums[index].toFixed(2)
            sums[index] += " "; //单位
          } else {
            sums[index] = ""; //计算存在nan单位
          }
        } else {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = sums[index].toFixed(2)
            sums[index] += " "; //单位
          } else {
            sums[index] = ""; //计算存在nan单位
          }
        }
        if (column.label == '发货单体积合计(m³)') {
          const values = result.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = sums[index].toFixed(3)
            sums[index] += " "; //单位
          }
        }
      });
      return sums;
    },
    // 查看子账户数据
    onFineshedchange(e) {
      if (e.target.checked == true) {
        this.onlyChid = true, //只展示分销商
        this.onlyZhu = false //只展示我的
        this.tabelIsLoading = true;
        this.filterForm.queryChild = 1
        const data = {
          // 查询子账户数据
          ...this.filterForm,
          pageNo: 1,
          pageSize: 10,
          ...this.terms
        };
        getProductList(data).then(res => {
          let data;
          if (typeof res.data == "string") {
            data = JSON.parse(res.data);
          } else {
            data = res.data;
          }
          this.tableData = data.list
          this.pagination.pageNo = data.currentPage
          this.pagination.pageSize = 10
          this.pagination.count = data.totalCount
          this.total = data.totalCount
          let tableData;
          tableData = res.data.list
          if (tableData.length > 0) {
            let result = [];
            let obj = {};
            for (let i = 0; i < tableData.length; i++) {
              tableData[i].ladingStatusTxt = ''
              tableData[i].attorneyLetter = ''
              if (!obj[tableData[i].orderCode]) {
                result.push(tableData[i]);
                obj[tableData[i].orderCode] = true;
              }
              if( tableData[i].ladingStatus == '2') {
                tableData[i].attorneyLetter =  tableData[i].link
              }
              if(  tableData[i].ladingStatus == 1 ) {
                tableData[i].attorneyLetter = '签署中'
              }
            }
          }
          this.pagination.pageNo = res.data.currentPage
          this.pagination.count = res.data.totalCount
          this.total = res.data.totalCount
          this.tabelIsLoading = false;
          this.tableData = tableData
          // this.tcolumns = JSON.parse(JSON.stringify(this.$refs.setTableRow.showList));
          // this.listChange(this.tcolumns);
        });
      } else {
        this.onlyChid = false, //只展示分销商
          this.onlyZhu = true //只展示我的
        this.filterForm.queryChild = 2
        this.getData()
      }
    },

    onFineshedchange2(e) {
      if (e.target.checked == true) {
        this.onlyChid = false, //只展示分销商
          this.onlyZhu = true //只展示我的
        this.tabelIsLoading = true;
        this.filterForm.queryChild = 2
        const data = {
          // 查询子账户数据
          ...this.filterForm,
          pageNo: 1,
          pageSize: 10,
          ...this.terms
        };
        getProductList(data).then(res => {
          let data;
          if (typeof res.data == "string") {
            data = JSON.parse(res.data);
          } else {
            data = res.data;
          }
          this.tableData = data.list
          this.pagination.pageNo = data.currentPage
          this.pagination.pageSize = 10
          this.pagination.count = data.totalCount
          this.total = data.totalCount
          let tableData;
          tableData = res.data.list
          if (tableData.length > 0) {
            let result = [];
            let obj = {};
            for (let i = 0; i < tableData.length; i++) {
              tableData[i].attorneyLetter = ''
              if (!obj[tableData[i].orderCode]) {
                result.push(tableData[i]);
                obj[tableData[i].orderCode] = true;
              }
                if( tableData[i].ladingStatus == '2') {
                  tableData[i].attorneyLetter =  tableData[i].link
                }
                if(  tableData[i].ladingStatus == 1 ) {
                  tableData[i].attorneyLetter = '签署中'
                }
            }
          }
          this.pagination.pageNo = res.data.currentPage
          this.pagination.count = res.data.totalCount
          this.total = res.data.totalCount
          this.tabelIsLoading = false;
          this.tableData = tableData
          // this.tcolumns = JSON.parse(JSON.stringify(this.$refs.setTableRow.showList));
          // this.listChange(this.tcolumns);
        });
      } else {
        this.onlyChid = true, //只展示分销商
          this.onlyZhu = false //只展示我的
        this.filterForm.queryChild = 1
        this.getData()
      }
    },

    // 初始化请求数据
    getData() {
      this.tabelIsLoading = true;
      let data = {
        ...this.filterForm,
        pageNo: this.pagination.pageNo,
        pageSize: this.pagination.pageSize,
        sortName: this.pagination.sortName,
        sortOrder: this.pagination.sortOrder,
        ...this.terms
      }
      getProductList(data).then(res => {
        let tableData;
        tableData = res.data.list
        if (tableData.length > 0) {
          let result = [];
          let obj = {};
          for (let i = 0; i < tableData.length; i++) {
            tableData[i].attorneyLetter = ''
            if (!obj[tableData[i].orderCode]) {
              result.push(tableData[i]);
              obj[tableData[i].orderCode] = true;
            }
            if( tableData[i].ladingStatus == '2') {
              tableData[i].attorneyLetter =  tableData[i].link
            }
            if(  tableData[i].ladingStatus == 1 ) {
              tableData[i].attorneyLetter = '签署中'
            }
          }
        }
        this.pagination.pageNo = res.data.currentPage
        this.pagination.count = res.data.totalCount
        this.total = res.data.totalCount
        this.tabelIsLoading = false;
        this.tableData = tableData
        // this.tcolumns = JSON.parse(JSON.stringify(this.$refs.setTableRow.showList));
        // this.listChange(this.tcolumns);
      });
    },
    // 表格方法 如果为true 就可以勾选 为false就不能
    checkSelectable(row) {
      return row.waitStorage === 'Y' && row.operateFlag === 'Y'
    },

    // 点击采购订单跳转
    linkClick(obj) {
      let routeUrl = this.$router.resolve({
        path: "/order/detail",
        query: {
          name: '海信采购进度',
          id: obj.orderId
        }
      });
      window.open(routeUrl.href, "_blank");

    },
    // 销售订单跳转
    salrorderLink(data) {
      let routeUrl = this.$router.resolve({
        path: "/order/progress",
        query: {
          name: '海信采购进度',
          id: data.orderId,
          code: data.sapOrderCode
        }
      })
      window.open(routeUrl.href, "_blank");
    },
    rowSelection(rows) {
      this.selRows = rows
    },
    pageSearch(pager) {
      this.pagination.pageNo = pager.pageNo;
      this.pagination.pageSize = pager.pageSize;
      this.getData();
    },
    // 搜索
    searchFun(arg) {
      // 正式环境pageNO为undefined问题修改
      this.pagination.pageNo = 1
      this.terms = {
        ...this.pagination.pageNo,
        ...this.pagination.pageSize,
        ...arg[0]
      };
      
      this.getData();
    },
    // 排序
    onTableChange(sorter) {
      const sortName = this.tcolumns.filter(it => it.prop == sorter.prop)
      if (sortName.length < 1) {
        return
      }
      if (sorter && sorter.order) {
        this.pagination.sortOrder =
          sorter.order == "ascending" ?
            "asc" :
            sorter.order == "descending" ?
              "desc" :
              "";
        this.pagination.sortName = sortName[0].sortName;
      } else {
        this.pagination.sortOrder = "";
        this.pagination.sortName = "";
      }
      this.getData();
    },
	// listChange(e) {
	// 	this.tcolumns = e
	// 	let item = {
	// 		label: "操作",
	// 		prop: "",
	// 		key: "btnGroup",
	// 		slot: "btnGroup",
	// 		fixed: 'right',
	// 		width: 200
	// 	}
	// 	this.tcolumns.push(item)
	// },
	//打印按钮点击
	printBtnClickFun(row){
	  //数据接口请求完毕


    this.isDataOk = false;

    const params = {
      orderId:row.orderId,
      pageNo: 1,
      pageSize: 999
    }
    findSalesOrderPrint(params).then(res => {
      
      if(res.data.code == 0 && res.data?.data?.data?.length > 0){
        this.printData.Master = res.data.data.rib;
        this.printData.Details = res.data.data.data;

        this.$nextTick(()=>{
          this.isDataOk = true;
        })

      }else{
        this.$message.warning('单据获取失败，请重试')
      }

    }).catch(err=>{
      this.$message.warning('单据获取失败，请重试')
    });
	},
	getPrice(item) {
	  return ((item.billPrice || 0) * 100) * (item.qty || 0) / 100
	},
  initTableColumns(tableColumns) {
    this.tcolumns = tableColumns.filter(j=>j.isShow === '1');
  },
  expandMore(){
    this.isExpand = !this.isExpand
  }
  }
}